import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Layout from '../hoc/layout';
import { useDispatch, useSelector } from 'react-redux';



const fallback = <p>....loading</p>;
const Indexpage = lazy(() => import('../Component/IndexPage'))
const Aboutus = lazy(() => import('../Component/Aboutus'))
const Services = lazy(() => import('../Component/Services'))
const Project = lazy(() => import('../Component/Project'))
const Ourteam = lazy(() => import('../Component/Ourteam'))
const Testimonial = lazy(() => import('../Component/Testimonial'))
const Contactus = lazy(() => import('../Component/Contact'));
const BulidingandBrideges = lazy(() => import('../Component/BulidingandBrideges'));
const Mining = lazy(() => import('../Component/Mininge'));
const Importexport = lazy(() => import('../Component/ImportExport'));
const Trading = lazy(() => import('../Component/Trading'));
const Fruitexport = lazy(() => import('../Component/Export_fruits'));
const Melondetail = lazy(() => import('../Component/Melondetail'));
const Kewidetail = lazy(() => import('../Component/Kiwidetail'));
const Orangesdetail = lazy(() => import('../Component/Orangesdetail'));
const Appaledetail = lazy(() => import('../Component/Appaledetail'));
const Stweabareydetail = lazy(() => import('../Component/Stweabareydetail'));
const Pomegranatedetail = lazy(() => import('../Component/Pomegranatedetail'));
const Vagitabletexport = lazy(() => import('../Component/Export_Vagitable'));
const Avaladetail = lazy(() => import('../Component/Avaladetail'));
const Carrotsdetail = lazy(() => import('../Component/Carrotsdetail'));
const Tomatodetail = lazy(() => import('../Component/Toamtodetail'));
const Bittergourddetail = lazy(() => import('../Component/Bittergourddetail'));
const Cauliflowerddetail = lazy(() => import('../Component/Cauliflowerddetail'));
const Drumstickddetail  = lazy(() => import('../Component/Drumstickddetail '));
const Oliexport = lazy(() => import('../Component/Export_Oil'));
const Olidetail = lazy(() => import('../Component/Oliexport'));
const Miningtdetail = lazy(() => import('../Component/Miningtdetail'));
const Sharetradingdetail = lazy(() => import('../Component/Sharetradingdetail'));
const Companypage = lazy(() => import('../Component/companypage'));
const Bulidingdetail = lazy(()=>import('../Component/Brdigesdetail'))
const Brdigesdetail = lazy(()=>import('../Component/Brdigesdetail'))
const Bulidinginformation = lazy(()=>import('../Component/BulidingInformation'))
const Bulidingdetail_1 = lazy(()=>import('../Component/Buliding-1detail'))
const ComrcialInformation = lazy(()=>import('../Component/ComrcialInformation'))
const Officedetail_1 = lazy(()=>import('../Component/Office1detail'))
function App() {
	let routes = (
		<Routes>
			<Route path="/" element={<Companypage />} />
			<Route path="/Indexpage" element={<Suspense fallback={fallback}><Indexpage /></Suspense>} />
			<Route path="/Aboutus" element={<Suspense fallback={fallback}><Aboutus /></Suspense>} />
			<Route path="/Services" element={<Suspense fallback={fallback}><Services /></Suspense>} />
			<Route path="/Project" element={<Suspense fallback={fallback}><Project /></Suspense>} />
			<Route path="/Ourteam" element={<Suspense fallback={fallback}><Ourteam /></Suspense>} />
			<Route path="/Testimonial" element={<Suspense fallback={fallback}><Testimonial /></Suspense>} />
			<Route path="/Contactus" element={<Suspense fallback={fallback}><Contactus /></Suspense>} />
			<Route path="/BulidingandBrideges" element={<Suspense fallback={fallback}><BulidingandBrideges /></Suspense>} />
			<Route path="/Mining" element={<Suspense fallback={fallback}><Mining/></Suspense>} />
			<Route path="/Importexport" element={<Suspense fallback={fallback}><Importexport/></Suspense>} />
			<Route path="/Trading" element={<Suspense fallback={fallback}><Trading/></Suspense>} />
			<Route path="/Fruitexport" element={<Suspense fallback={fallback}><Fruitexport/></Suspense>} />
			<Route path="/Vagitabletexport" element={<Suspense fallback={fallback}><Vagitabletexport/></Suspense>} />
			<Route path="/Melondetail" element={<Suspense fallback={fallback}><Melondetail /></Suspense>} />
			<Route path="/Kewidetail" element={<Suspense fallback={fallback}><Kewidetail /></Suspense>} />
			<Route path="/Orangesdetail" element={<Suspense fallback={fallback}><Orangesdetail /></Suspense>} />
			<Route path="/Appaledetail" element={<Suspense fallback={fallback}><Appaledetail /></Suspense>} />
			<Route path="/Stweabareydetail" element={<Suspense fallback={fallback}><Stweabareydetail /></Suspense>} />
			<Route path="/Pomegranate" element={<Suspense fallback={fallback}><Pomegranatedetail /></Suspense>} />
			<Route path="/Olidetail" element={<Suspense fallback={fallback}><Olidetail/></Suspense>} />
			<Route path="/Miningtdetail" element={<Suspense fallback={fallback}><Miningtdetail/></Suspense>} />
			<Route path="/Sharetradingdetail" element={<Suspense fallback={fallback}><Sharetradingdetail/></Suspense>} />
			<Route path="/Bulidingdetail" element={<Suspense fallback={fallback}><Bulidingdetail/></Suspense>} />
			<Route path="/Brdigesdetail" element={<Suspense fallback={fallback}><Brdigesdetail/></Suspense>} />
			<Route path="/Bulidinginformation" element={<Suspense fallback={fallback}><Bulidinginformation/></Suspense>} />
			<Route path="/Bulidingdetail_1" element={<Suspense fallback={fallback}><Bulidingdetail_1/></Suspense>} />
			<Route path="/ComrcialInformation" element={<Suspense fallback={fallback}><ComrcialInformation/></Suspense>} />
			<Route path="/Officedetail_1" element={<Suspense fallback={fallback}><Officedetail_1/></Suspense>} />
			<Route path="/Avaladetail" element={<Suspense fallback={fallback}><Avaladetail/></Suspense>} />
			<Route path="/Carrotsdetail" element={<Suspense fallback={fallback}><Carrotsdetail/></Suspense>} />
			<Route path="/Tomatodetail" element={<Suspense fallback={fallback}><Tomatodetail/></Suspense>} />
			<Route path="/Bittergourddetail" element={<Suspense fallback={fallback}><Bittergourddetail/></Suspense>} />
			<Route path="/Cauliflowerddetail" element={<Suspense fallback={fallback}><Cauliflowerddetail/></Suspense>} />
			<Route path="/Drumstickddetail" element={<Suspense fallback={fallback}><Drumstickddetail/></Suspense>} />
			<Route path="/Oliexport" element={<Suspense fallback={fallback}><Oliexport/></Suspense>} />
			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	);

	return (
		<div className="App">

			<Layout>{routes}</Layout>

		</div>
	);
}

export default App