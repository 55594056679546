import React from "react";

import { Link } from "react-router-dom";

const Header = () => {
    return (
        <div>
            <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-5 py-lg-0 mb-5">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#">Brand</a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="main_nav">
                        <ul class="navbar-nav">
                            <Link to="/"><li class="nav-item active"> <a class="nav-link" href="#">Home </a> </li></Link>
                            <Link to="/Aboutus"><li class="nav-item"><a class="nav-link" href="#"> About </a></li></Link>
                            <li class="nav-item dropdown" id="myDropdown">
                                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Infrastracuher</a>
                                <ul class="dropdown-menu">
                                    <li><Link to="/Bulidinginformation"><a class="dropdown-item" href="#"> Residential  </a></Link></li>
                                    <li><Link to="/ComrcialInformation"><a class="dropdown-item" href="#"> Commercial </a></Link></li>
                                    <li><Link to="/Brdigesdetail"><a class="dropdown-item" href="#"> Bridges & flayovers</a></Link></li>
                                    {/* <li> <a class="dropdown-item" href="#"> Importexport &raquo; </a>
                                        <ul class="submenu dropdown-menu">
                                            <Link to="/Vagitabletexport"><li><a class="dropdown-item" href="#">Vagitable</a></li></Link>
                                            <Link to="/Fruitexport"><li><a class="dropdown-item" href="#">Fruit</a></li></Link>
                                            <Link to="/Oliexport"><li><a class="dropdown-item" href="#">Crude Oil</a></li></Link>
                                        </ul>
                                    </li>
                                    <li><a class="dropdown-item" href="#"> Mining &raquo; </a>
                                        <ul class="submenu dropdown-menu">
                                            <Link to="/Miningtdetail"><li><a class="dropdown-item" href="#">Gold</a></li></Link>
                                            <Link to="/mining"><li><a class="dropdown-item" href="#">Silver</a></li></Link>
                                        </ul>
                                    </li>
                                    <li><a class="dropdown-item" href="#"> Trading &raquo;</a>
                                        <ul className="submenu dropdown-menu">
                                            <Link to="/ShareTradingdetail"><li><a class="dropdown-item" href="#">Forex</a></li></Link>
                                            <Link to="/ShareTradingdetail" ><li><a href="Shretradingdata" class="dropdown-item">Crypto</a></li></Link>
                                            <Link to="/ShareTradingdetail" ><li><a class="dropdown-item" href="#">Stocks</a></li></Link>
                                            <Link to="/ShareTradingdetail" ><li><a class="dropdown-item" href="#">OTC</a></li></Link>
                                        </ul>
                                    </li> */}
                                </ul>
                            </li>
                            <li class="nav-item dropdown" id="myDropdown">
                                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Importexport</a>
                                <ul class="dropdown-menu">
                                    <li><Link to="/Vagitabletexport"><a class="dropdown-item" href="#"> Vagitable  </a></Link></li>
                                    <li><Link to="/Fruitexport"><a class="dropdown-item" href="#"> Fruit </a></Link></li>
                                    <li><Link to="/Oliexport"><a class="dropdown-item" href="#"> Crude Oil</a></Link></li>
                                </ul>
                            </li>
                            <li class="nav-item dropdown" id="myDropdown">
                                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Mining</a>
                                <ul class="dropdown-menu">
                                    <li><Link to="/Miningtdetail"><a class="dropdown-item" href="#"> Gold  </a></Link></li>
                                    <li><Link to="/mining"><a class="dropdown-item" href="#"> Silver </a></Link></li>
                                </ul>
                            </li>
                            <li class="nav-item dropdown" id="myDropdown">
                                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Trading</a>
                                <ul class="dropdown-menu">
                                    <li><Link to="/ShareTradingdetail"><a class="dropdown-item" href="#"> Forex  </a></Link></li>
                                    <li><Link to="/ShareTradingdetail"><a class="dropdown-item" href="#"> Crypto </a></Link></li>
                                    <li><Link to="/ShareTradingdetail"><a class="dropdown-item" href="#"> Stocks </a></Link></li>
                                    <li><Link to="/ShareTradingdetail"><a class="dropdown-item" href="#"> OTC </a></Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;
