import React from "react";
import Header from "../Component/Header";
// import Footer from '../component/footer'

const Layout = props => {
    return (
        <React.Fragment>

            <main className="page-content">
                {props.children}
            </main>
            {/* <Footer/> */}
        </React.Fragment>
    )
}

export default Layout;